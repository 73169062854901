@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,900;1,400;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap";
:root{--bg:#f5f5f5;--bg1:#00000015;--bg2:#00000061;--bg3:#dee6ff;--bg4:#0094b1;--fg:#222;--fg1:#444;--fg2:#0094B1;--boxShadow:2px 5px 10px #aaa;--border1:none;--colorBorder1:#002C66;--colorBorder2:#0094B1;--filter:none;--color1:#8a0000}
body{background:var(--bg)}html{scroll-behavior: smooth;}
*{font-family:Poppins;padding:0;margin:0;box-sizing:border-box;scroll-behavior:smooth}
.navbar{width:100%;background-color:#000000b7;z-index:1000;position:fixed!important;padding:15px 20px}
.navbar-nav li a{margin:5px 10px;padding:10px 15px!important;transition:.15s all ease-in-out;border-radius:7px;font-size:20px;color:#fff;display: flex;}
.navbar-nav li .active,.navbar-nav li a:hover{background:#ffffff75;transition:.15s all ease-in-out}
.navbar-nav li a div{width: 30px;display: flex;justify-content: center;margin-right: 7px;}
.navbar-toggler{border: none!important;box-shadow: none!important;}
.navbar-toggler i{font-size: 30px;}
.switches ul{display: flex;}
.nav-item.x{height: 50px;display: flex;justify-content: center;align-items: center;}
.navigation{position:fixed;display:flex;flex-direction:column;align-items:center;z-index:2;width:100%;transition:.2s;left:50%;transform:translate(-50%,0)}
.navigation.scrolled{transition:.2s;transform:translate(-50%,20px)}
.navig{width:98%;border-radius:20px;padding:15px 30px;height:80px;background:none;border:none;margin:0 auto;transition:.4s;display:flex;align-items:center}
.logoInside{height:60px}
.logoInside img{height:100%;transform:translateY(7px);}
.navig.scrolled{background:var(--bg2);transition:.4s}
.navig.backgrounded{background:var(--bg2);width:100%;border-radius:0;transition:.4s}
.navig .items{width:100%;display:flex;height:100%}
.navig .items ul{display:flex;align-items:center;justify-content:space-around}
.navig .items ul:first-child{width:100%}
.navig .items .item .link{color:#FFF;border:2px solid transparent;padding:5px 25px;border-radius:10px;transition:.5s}
.navig .items .item .link:hover{border:2px solid #FFF;transition:.3s}
.navig .items .item .link.active{border:2px solid #FFF;background:#FFF;font-weight:600;color:#000;transition:.3s}
.navig .items .item .link.x{margin-left:10px}
.navig .items .item .link:not(.x){margin:0 10px}
.toggle1,.toggle2,.toggle4,.toggle5{margin:0 10px;position:relative;height:29px;width:60px;border:2px solid #fff;padding:0 3px;display:flex;border-radius:20px;align-items:center;justify-content:space-between;transform:scale(1.25);cursor:pointer;}
.toggle1{padding-right:6px}
.fa-sun{color:#f39c12}
.fa-moon{color:#f1c40f;margin-left:4px}
.ball{width:22px;height:22px;background:#fff;position:absolute;border-radius:20px;transition:.5s}
#toggle1:checked+.toggle1 .ball,#toggle2:checked+.toggle2 .ball{background:#fff;top:1.5px;transform:translate(30px);transition:.5s}
#toggle4:checked+.toggle4 .ball,#toggle5:checked+.toggle5 .ball{background:#fff;top:1.5px;transform:translate(30px);transition:.5s}
.toggle3{padding:10px 25px}
#toggle3:checked+label{border:5px solid #fff;padding:10px 20px}
#toggle1,#toggle2,#toggle3,#toggle4,#toggle5{display:none}
.spinner{display:flex;align-items:center;width:100%;justify-content:center;height:100vh}
.spinner h1{color:#FFF}
.spinner svg{width:70px;height:70px;animation:rotate 1s cubic-bezier(1,-.01,.5,1) infinite;margin:20px}
.spinner svg circle:first-child{width:100%;height:100%;fill:none;stroke-width:7;stroke-linecap:round;transform:translate(5px,5px);stroke-dasharray:200;stroke-dashoffset:100;z-index:100;animation:changeColor1 2s cubic-bezier(1,-.01,.5,1) infinite}
.spinner svg circle:nth-child(2){z-index:0;width:100%;height:100%;fill:none;stroke-width:7;stroke-linecap:round;transform:translate(5px,5px);stroke-dasharray:200;stroke-dashoffset:700;animation:changeColor2 2s cubic-bezier(1,-.01,.5,1) infinite}
.n{user-select:none;-moz-user-select:none;-khtml-user-select:none;-webkit-user-select:none;-o-user-select:none}
.header{position:relative;height:600px;width:100%;overflow-x:clip}
.header.x{height:80vh;background:linear-gradient(-45deg,#0094B1,#002C66)}
.header .bg img.hexagon{position:absolute;z-index:-1;transform:rotate(180deg);top:-40vw;width:100vw}
.header .bg img.hexagon:nth-child(2){filter:blur(30px);transform:rotate(180deg);z-index:-2}
.header .headerContainer{display:flex;height:760px;align-items:flex-end;width:100%;justify-content:center;padding-bottom:150px;background:linear-gradient(-45deg,#0094B1,#002C66)}
.header.x .headerContainer{padding-bottom:0;height:80vh;align-items:center}
.headerButtons{display:flex;margin:15px 0}
.header.x .headerContainer .headerTitle{width:100%}
.header .headerContainer .headerTitle h1{color:#FFF;font-weight:700;font-size:50px;font-family:Montserrat;text-align:center;width:100%}
.header.x .headerContainer .headerTitle h1{font-size:5vw}
.header .headerContainer .headerContent{display:flex;flex-direction:column;align-items:center;width:50%}
footer{padding:40px 0;background:linear-gradient(-45deg,#0094B1,#002C66);color:#fff;display:flex;flex-direction:column;align-items:center}
footer ul{padding:0;list-style:none;text-align:center;font-size:18px;line-height:1.6;margin-bottom:0}
footer li{padding:0 10px}
footer ul a{color:inherit;text-decoration:none;opacity:.8}
footer ul a:hover{opacity:1}
footer .social{text-align:center}
footer .social>a{font-size:24px;width:40px;height:40px;line-height:40px;display:inline-block;text-align:center;border-radius:50%;margin:0 8px;color:inherit;opacity:.75}
footer .social>a:hover{opacity:.9}
footer .copyright{margin-top:15px;text-align:center;font-size:13px;color:#eee;margin-bottom:0}
footer img{margin:20px 0}
.bttn{transition:.15s all ease-in-out;box-shadow:2px 2px 10px #333;display:flex;align-items:center;justify-content:space-between;padding:10px 30px;text-transform:uppercase;color:#FFF;border-radius:7px;margin:5px}
.first{background:linear-gradient(45deg,#0094B1,#09b3d5);margin:5px;border:none}
.primary{background:linear-gradient(45deg,#FF5B00,#FF9600);margin:5px;border:none}
.secondary{background:none;border:2px solid #FFF;margin:5px}
.bttn:hover{transform:scale(1.1);transition:.15s all ease-in-out}
.bttn img{width: 35px;}
.bttn h5{font-weight:600;font-size:20px;margin:0 5px}
.secondary h5{font-size:16px}
.cards{width:100%;height:600px;display:flex;align-items:center;justify-content:center}
.card{transition:.2s;display:flex;flex-direction:column;align-items:center;justify-content:center;height:300px;width:270px;border:none;border-radius:20px;margin:0 30px;padding:20px;background:var(--bg);box-shadow:0 0 40px #008cab80}
.card:hover{transform:scale(1.1);transition:.4s cubic-bezier(.3,2.26,.36,1.59)}
.card:hover img{transition:.3s;margin-top:30px;transform:scale(1.2);transition-delay:.1s}
.card img{width:100px;margin-bottom: 30px;transition:.2s}
.card h3{text-align:center;font-weight:600;font-size:26px;margin:15px 0;color:var(--fg)}
.card p{text-align:center;font-weight:600;font-size:18px;color:#aaa}
.card a{color:#0095FF;font-weight:600}
.abtCompany{width:100%;display:flex;align-items:center;justify-content:center;height:600px}
.abtCompany .thumbnail{height:500px;width:500px;overflow:hidden;position:relative;border-radius:20px;margin-right:30px;background:url(../img/img1.jpg) no-repeat center;background-size:cover}
.abtCompany .body{width:50%;height:500px;display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch;padding:20px 10px}
.abtCompany .body h1{font-family:Merriweather;font-weight:600;color:var(--fg);border-bottom:2px solid #0093b132;padding-bottom:10px;margin-bottom:20px}
.abtCompany .body h1 em{font-family:Merriweather;color:#0094B1}
.abtCompany .body p{color:var(--fg1)}
.abtCompany .body .body1{height:100%;display:flex;flex-direction:column;justify-content:space-evenly}
.abtCompany .body .body1 .body2{display:flex;align-items:center}
.abtCompany .body .body1 .body2 div:first-child{display:flex;justify-content:center;align-items:center;width:70px}
.abtCompany .body .body1 .body2 div:first-child i{font-size:25px;color:#0094B1}
.abtCompany .body .body1 .body2 div:nth-child(2) h2{font-size:30px;color:var(--fg);margin-bottom:5px}
.services{display:flex;flex-direction:column;align-items:center;width:100%;margin:20px 0}
.headline{display:flex;flex-direction:column;align-items:center;color:#FFF;background:linear-gradient(-45deg,#0094B1,#002C66);width:100%;padding:30px 0;margin:20px 0}
.headline h1{font-family:Merriweather;font-weight:700;letter-spacing:1px}
.services .servicesRow{display:flex;justify-content:center;width:100%;margin:20px 0}
.services .servicesRow .card{transition:.2s;display:flex;flex-direction:column;align-items:center;justify-content:center;height:320px;width:325px;border:var(--border1);border-radius:10px;margin:0 20px;padding:30px;background:var(--bg);box-shadow:var(--boxShadow);overflow:hidden;cursor:pointer}
.services .servicesRow .card .content{display:flex;flex-direction:column;align-items:center;width:100%;z-index:1}
.services .servicesRow .card .content div:first-child{display:flex;align-items:center;justify-content:center;margin-bottom:20px;height:70px;border-radius:180px;height:80px;width:80px;transition:.3s}
.services .servicesRow .card:hover .content div:first-child{transition:.3s;transform:scale(1.3);transition-delay:.1s}
.services .servicesRow .card:hover img{transition:.3s;transform:scale(1);transition-delay:.1s}
.services .servicesRow .card div:first-child img{width:40px;margin:0}
.services .servicesRow .card div:nth-child(2){height:90px}
.services .servicesRow .card div h3{font-size:25px}
.services .servicesRow .card:hover div h3,.services .servicesRow .card:hover div p{color:#FFF;transition:.5s}
.services .servicesRow .card div.circle{transition:.5s cubic-bezier(0.25,0.57,0.26,1.06);position:absolute;height:100%;border-radius:800px;width:100%;background-color:#ff6000;top:-310px;left:0;transform:scale(1.5)}
.services .servicesRow .card:hover div.circle{top:0;transition:.5s cubic-bezier(0.25,0.57,0.26,1.06)}
.services .servicesRow:nth-child(2) .card:first-child div.circle,.services .servicesRow:nth-child(3) .card:nth-child(2) div.circle{background:#ff6000}
.services .servicesRow:nth-child(2) .card:nth-child(2) div.circle,.services .servicesRow:nth-child(3) .card:nth-child(3) div.circle{background:#0d92b1}
.services .servicesRow:nth-child(2) .card:nth-child(3) div.circle,.services .servicesRow:nth-child(3) .card:first-child div.circle{background:#00af7e}
.services .servicesRow:nth-child(2) .card:first-child .content div:first-child,.services .servicesRow:nth-child(3) .card:nth-child(2) .content div:first-child{background:hsl(21,100%,86%)}
.services .servicesRow:nth-child(2) .card:nth-child(2) .content div:first-child,.services .servicesRow:nth-child(3) .card:nth-child(3) .content div:first-child{background:hsl(190,100%,86%)}
.services .servicesRow:nth-child(2) .card:nth-child(3) .content div:first-child,.services .servicesRow:nth-child(3) .card:first-child .content div:first-child{background:hsl(163,100%,86%)}
.contact form{margin:0 auto 20px;width:95%;border-radius:10px;padding:10px}
.contact form .rowField{display:flex;width:90%;margin:15px auto;justify-content:space-between}
.contact form .rowField.c{align-items:center;justify-content:center}
.contact form .rowField.d{flex-direction:column;align-items:stretch}
.contact form .rowField.e{display:flex;flex-direction:column;border-radius:20px;margin:15px auto}
h5.error{padding:20px;color:var(--color1);width:100%}
h5.error.e{padding:5px}
h5.error i{margin:0 20px}
.primaryInput{color:var(--fg);transition:.5s;padding:15px 20px;border:2px solid var(--colorBorder2);border-radius:100px;width:100%;font-family:Montserrat;outline:none;margin:5px;background:none}
.primaryInput:hover,.primaryInput:focus,.textArea:focus,.textArea:hover{border:2px solid var(--colorBorder1);transition:.5s}
.textArea{color:var(--fg);padding:15px 20px;border:2px solid var(--colorBorder2);width:100%;font-family:Montserrat;outline:none;margin:5px;background:none;border-radius:10px;transition:.5s}
.fileField{margin:5px;background:#0094B1;padding:5px 10px;border-radius:10px;display:flex;flex-direction:column;justify-content:space-between;align-items:center;transition:.3s;cursor:pointer;transition:.5s;overflow:hidden;position:relative;height:300px}
.fileField:hover{background:#007086;transition:.3s}
.fileField .content{display:flex;flex-direction:column;align-items:center;justify-content:space-evenly;height:100%;width:100%}
.fileField .content h3{text-align:center;color:#FFF;font-size:30px;font-weight:600}
.fileField .content span{font-size:18px;font-weight:400;font-style:italic}
.fileField .content i{color:#FFF;font-size:50px}
.files{margin-top:20px;cursor:default;display:flex;flex-direction:column;align-items:stretch;width:100%;height:280px;background:var(--bg1);border-radius:20px;overflow-y:scroll}
.files .file{transition:.2s;display:flex;align-items:center;justify-content:space-between;padding:20px 10px;width:100%}
.files .file:hover{transition:.2s;background:#0000000f}
.files .file .details{display:flex;align-items:center;width:100%}
.files .file .details .fileExtensionThumbnail{margin:0 20px}
.files .file .details .fileExtensionThumbnail img{width:40px}
.files .file .details .fileDetails{width:300px;overflow:clip}
.files h4,.files h5{color:var(--fg);text-overflow:ellipsis;white-space:nowrap;overflow:hidden;color:var(--fg)}
.files .file .remove{cursor:pointer;transition:.3s;margin:0 10px;width:45px;height:45px;border-radius:500px;display:flex;align-items:center;justify-content:center}
.files .file .remove:hover{background:#ffffffbb;transition:.3s}
.files .file i{font-size:25px;color:var(--color1)}
.fileInput{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;z-index:0;cursor:pointer}
.contentt{display:flex;margin:0 30px;align-items:stretch;width:100%;overflow:hidden;position:relative}
.contentt .content{display:flex;flex-direction:column;align-items:center;width:70%;margin:5px}
.contentt .content h3{text-align:center;margin:20px 0;border-bottom:2px solid #0093b132;padding-bottom:10px;width:80%;font-family:Montserrat;font-weight:600;color:#0094B1}
.contentt .content p{text-align:justify;margin:10px 20px;line-height:25px;font-size:18px;color:var(--fg)}
.content .contentRow{height:50vh;display: flex;align-items: center;justify-content: space-around;margin: 30px;}
.content .contentRow p{text-align:justify;margin:10px 20px;line-height:25px;font-size:18px;color:var(--fg)}
.content .contentRow img{height: 100%;border-radius: 30px;}
.contentt .thumbnail{margin:20px;width:30%;height:auto;background:url(../img/img2.jpg) center no-repeat;border-radius:20px;background-size:cover}
.contentt.c2{flex-direction:column;align-items:stretch}
.contentt.c2 .content{width:100%;align-items:stretch}
.contentt.c2 .content .headContent h4{text-align:center;margin:20px;border-bottom:2px solid #0093b132;padding-bottom:10px;font-family:Montserrat;font-weight:600;color:#0094B1}
.contentt.c2 .thumbnail{background:none;width:auto;height:auto;width:35vh;position:absolute;bottom:28px;right:31px;scale:1.2;opacity:.1;filter:var(--filter);z-index:-1}
.info{align-self:flex-start}
.info li{color:#FFF;font-size:16px;text-align:left;align-self:flex-start;margin:10px 0}
.adminLogin{background:linear-gradient(-45deg,#0094B1,#002C66);width:100%;height:800px;overflow:hidden;display:flex;flex-direction:column;align-items:center;justify-content:center}
.adminLogin .credentials{margin-top:20px;width:60%;background:var(--bg);padding:20px;border-radius:20px;height:500px;display:flex;flex-direction:column;justify-content:space-evenly;align-items:center}
.adminLogin .credentials .title h1{color:var(--fg2);text-align:center;margin:20px 0;font-weight:600}
.inputs{width:100%;display:flex;align-items:stretch;justify-content:space-between;flex-direction:column}
.inputs .iRow{display:flex;align-items:center;margin:10px 15px 10px 0}
.inputs .iRow i{color:var(--fg2);font-size:20px;margin:0 15px}
.inputs .iRow.title {justify-content: center;margin: 40px 0;}
.inputs .iRow.title h3{color: var(--fg);}
.inputs .iRow.title h3 strong{font-weight: 600;background: #0094b1;color: #FFF;padding: 5px 10px;border-radius: 10px;font-size: 25px;}
.admin{display:flex;align-items:flex-end;justify-content:center;flex-direction: column;padding:100px 20px 50px 70px}
.dataTable{border-radius:20px;height:80vh;width:100%;overflow-y:scroll;border: 2px solid var(--fg);}
.dataTable.noScroll{overflow-y:hidden;border: none;border-radius: 0;}
.dataTable.noBorder{border: none;border-radius: 0;}
.dataTable.j{display: flex;flex-direction: column;justify-content: space-around;}
.dataRow{display:flex;width:100%;align-items:center;margin-bottom:10px;padding:20px 10px;color:var(--fg);transition:.2s}
.dataRow:hover{background-color:var(--bg1);transition:.2s}
.dataRow .date{display:flex;justify-content:center}
.dataRow .date .d.h{padding:0}
.dataRow .date .d{display:flex;flex-direction:column;justify-content:center;align-items:center;width:80px!important;height:80px;background:var(--bg1);border-radius:100px;padding-bottom:5px;margin:0 10px}
.dataRow .date .d div:first-child h5{font-size:30px}
.dataRow .date .d div:first-child h5.h{font-size:23px}
.dataRow .date .d div:nth-child(2) h5{font-size:12px}
.dataRow .nameAndPhone div h5{margin:10px 0}
.dataRow .nameAndPhone div:first-child h5{font-size:25px}
.dataRow .nameAndPhone div:last-child h5{font-size:16px}
.actions{display:flex;align-items:center;justify-content:center}
.btnIcon{padding:10px;border-radius:10px;margin:0 5px;transition: .2s;}
.btnIcon:hover{transition: .2s;}
.b1{background:#066b2e;}
.b1:hover{background:#0bbd52;}
.b2{background:#0547aa;}
.b2:hover{background:#1d77ff;}
.b3{background:#840a0a;}
.b3:hover{background:#ff1d1d;}
.b4{background:#530a84;}
.b4:hover{background:#7e0cca;}
.actions div button i{font-size:30px;color:#FFF}
.actions div button.disabled,.dataRow .actions div button.disabled:hover{background:#aaa;cursor:not-allowed}
.actions div button img{height:30px}
.dataRow .date{width:20%}
.dataRow .nameAndPhone{width:35%}
.dataRow .email{width:30%}
.dataRow .actions{width:15%}
.dataRow .name{width: 50%;display: flex;justify-content: center;}
.dataRow .roleDiv{width: 24%;display: flex;justify-content: center;}
.dataRow .actions{width: 25%;display: flex;justify-content: center;}
.reqRowContainer{padding: 0 10px;}
.reqRowContainer.e{padding: 0 20px;}
.reqRow{display: flex;width: 100%;align-items: stretch;}
.reqRow .reqColumn{display: flex;flex-direction: column;align-items: center;width: 50%;margin: 0 10px;color:var(--fg)}
.reqRow .reqColumn div:last-child h5{font-weight: 600;}
.reqColumn div:first-child{width: 100%;border-bottom: 2px solid var(--fg);text-align: center;padding: 20px;margin-bottom: 20px;}
.reqColumn.e{color:var(--fg)}
.reqColumn.e div:last-child{border: 2px solid var(--fg);height: 270px;overflow-y: scroll;padding: 10px;}
.barMenu{z-index:5;top:80px;position:fixed;width:50px;height:calc(100vh - 80px);background:#0b3c86;transition:.3s cubic-bezier(.8,.36,.07,1.08);overflow: hidden;}
.barMenu.expanded{width:300px;transition:.3s cubic-bezier(.8,.36,.07,1.08)}
.barMenu .logo{width: 300px;display: flex;justify-content: center;}
.barMenu .logo img{width:200px;}
.barMenu .category{color: #FFF;display: flex;height: 10%;align-items: center;width: 300px;transition:.3s;cursor: pointer;}
.barMenu .category:hover{background: var(--bg2);height: 85px;transition:.3s}
.barMenu .category.active{background: var(--bg2);transition:.3s}
.barMenu .category .icon{width: 50px;display: flex;justify-content: center;align-items: center;font-size: 25px;margin-right: 20px;}
.barMenu .category .icon img{width: 50%;height: 50%;}
.userInfo{width: 300px;display: flex;flex-direction: column;align-items: center;margin: 20px 0;}
.userInfo h3{font-size: 26px;color: #FFF;font-weight: 600;margin-bottom: 10px;}
h4.role{text-transform: uppercase;padding: 5px 10px;border-radius: 10px;font-size: 16px;}
h4.role.role1{color: #5e0091;background: #f0deff;}
h4.role.role2{color: #00505e;background: #def2ff;}
h4.role.role3{color: #00910c;background: #deffe2;}
.index{color: #FFF;font-size: 30px;position: absolute;bottom: 20px;left: 15px;transition: .3s cubic-bezier(.8,.36,.07,1.08);transform: translateX(0px);}
.index.expanded{transform: rotate(180deg) translateX(-250px);transition: .3s cubic-bezier(.8,.36,.07,1.08);}
.buttonDiv{width: 100%;justify-content: center;display: flex;align-items: center;}
.label{padding: 10px 15px;margin: 0 10px;font-size: 20px;border-radius: 10px;cursor: pointer;transition:.2s;color: var(--fg);}
#role1:checked + .l1{background: #0094b1;color: #FFF;transition:.2s;}
#role2:checked + .l2{background: #0094b1;color: #FFF;transition:.2s;}
.alert.v{display:flex;justify-content: space-between;align-items: center;width: 100%;}
.alert.v i{font-size: 20px;cursor: pointer;}
.alert.v.padded{margin: 20px;}
.pagination{width: 100%;display: flex;align-items: center;justify-content: center;margin: 35px 0;}
.pagination .controller{cursor: pointer;border: 2px solid #002C66;border-radius: 10px; background: none;width: 40px;height: 40px;color: var(--fg);display: flex;align-items: center;justify-content: center;font-size: 20px;transition:.5s;}
.pagination .controller:hover,.pagination .controller.active{border: 2px solid #002C66;background: #002C66;width: 40px;height: 40px;color: #FFF;transition:.2s;}
.pagination .pageNumbers {margin: 0 10px;display: flex;}
.pagination .pageNumbers .pageNumber.controller{margin: 0 5px;display: flex;}
.postCard{margin: 15px;display: flex;flex-direction: column;align-items: stretch;height: 470px;border-radius: 20px;width: 430px;overflow: hidden;background: var(--bg3);padding: 0;cursor: pointer;box-shadow: 5px 5px 10px #0000003e;}
.postCard.horizontal{flex-direction: row;width: calc(100% - 25px);height: 200px;cursor: default;padding-right: 20px;justify-content: space-between;}
.postCard.horizontal .thumbnail{height: 100%;width: 425px;}
.postCard.horizontal .content{height: 100%;justify-content: center;}
.postCard.horizontal:hover .thumbnail img{transform: scale(1);transition:none;filter: none;}
.postCard:hover .thumbnail img{transform: scale(1.5);transition: .3s ease-out;filter: saturate(0.2);}
.postCard .thumbnail{width: 100%;height:280px;display: flex;align-items: center;justify-content: center;overflow: hidden;}
.postCard .thumbnail img{height: 100%;transition: .3s ease-in-out;}
.postCard.horizontal .thumbnail img{width: 100%;}
.postCard .content{height: 130px;color: var(--fg);padding: 10px 20px;display: flex;flex-direction: column;}
.postCard .content .details h6{color: var(--fg1);}
.postCard .content .title{margin: 10px 0;}
.postCard .content .title h4{color: var(--fg);font-weight: 600;font-family: Merriweather;}
.postRow{margin: 20px;justify-content: center;}
.headlineTitle{width: 100%;height: 80px;background: var(--bg3);color: var(--fg);display: flex;align-items: center;padding: 10px 30px;margin-bottom: 10px;}
.headlineTitle h2{font-weight: 600;}
.post{display: flex;flex-direction: column;align-items: stretch;width: 100%;color: var(--fg);}
.myCarousel{display: flex;flex-direction: column;align-items: center;}
.myCarousel .imgs{overflow-x: hidden;position: relative;border-radius: 20px;margin: 0 auto;}
.myCarousel .subImgs{display: flex;align-items: center;height: 100%;position: absolute;transition: .5s cubic-bezier(.3,.51,.23,1.01);}
.myCarousel .imgs img{height: 100%;}
.myCarousel .controller{display:flex;font-size: 20px;align-items: baseline;margin-top: 20px;}
.myCarousel .controller i{width: 45px;height: 45px;border-radius: 40px;border: 2px solid var(--fg);display: flex;align-items: center;justify-content: center;cursor: pointer;transition:.2s}
.myCarousel .controller i:hover{background: var(--fg);color: var(--bg);transition:.2s}
.myCarousel .info{font-size: 30px;margin: 0 15px;font-family: Montserrat;line-height: 45px;}
.post .details h4{text-align: center;background: #0094b1;padding: 15px 0;width: 100%;margin-top: 20px;color: #FFF;}
.post .content{width:70%;margin: 0 auto 50px auto;}
.post .content h6{margin: 25px 0;font-style: italic;}
.post .longDescription{width:100%}
.post .longDescription p{line-height: 30px;text-align: justify;}
.nothing{width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;}
.nothing img{height: 50%;}
.nothing h5{color: var(--fg);margin-top: 20px;font-size: 30px;}
.thumbnails{width: 100%;overflow-x: hidden;display: flex;flex-direction: column;align-items: center;}
.thumbnails .addThumbnail{width:100%;display: flex;justify-content: center;margin: 10px 0;}
.thumbnails .children{display: flex;flex-direction: column;align-items: stretch;width: 80%;padding: 10px 15px;}
.thumbnails .children .thumbRow{display: flex;align-items: center;height: 100px;width: 100%;margin: 5px 0;}
.thumbnails .children .thumbRow .Img{height: 100%;width: 180px;margin-right: 20px;}
.thumbnails .children .thumbRow .Img img{height: 100px;width: 170px;border-radius: 20px;border: 2px solid var(--fg);}
.thumbnails .children .thumbRow .FileName{width: 100%;color: var(--fg);}
.createPostInputs{width: calc(100% - 15px);display: flex;flex-direction: column;align-items: stretch;padding: 20px 10px;}
.createPostInputs .inputDiv{width: 100%;display: flex;flex-direction: column;align-items: flex-end;}
.createPostInputs .inputDiv .counter{margin: 2px 5px 10px 0;color: var(--fg);}
.confirmCreatePost {display: flex;justify-content: center;}
.modal-body{height:300px}
.modal-body.x{height:auto}
.modal-content{color: var(--fg);background: var(--bg);}
.slider{margin: 5px 15px;}
.nofile{width:100%;height:100%;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.containerDiv{background: #007086;}
.closeBtn{color: var(--fg);padding: 5px 10px;font-size: 20px;border-radius: 5px;background: none;}
.abtHeading{height: calc(100vh - 80px);display: flex;align-items: center;justify-content: center;text-align: center;padding: 0 20px;position: relative;overflow: hidden;}
.abtHeading h1{margin-bottom: 20px;border-bottom: 1.5px solid var(--fg);padding-bottom: 20px;font-weight: 600;}
.abtHeading .content{width: 70%;color: var(--fg);}
.abtHeading p{line-height: 22px;font-size: 17px;margin-bottom: 10px;text-align: justify;}
.abtHeading .circle{position: absolute;width: 300px;z-index: -1;}
.abtHeading .circle.c1{bottom: 0;left: 0;transform: translate(-50%,50%);}
.abtHeading .circle.c2{top: 0;right: 0;transform: translate(50%,-50%);}
.advantages1{display: flex;padding: 20px 10px;justify-content: center;height: 300px;}
.adv{display: flex;align-items: center;width: 30%;}
.advNumber{width: 100px;padding: 0px 10px;}
.advNumber h1{font-family: Merriweather;font-weight: 900;color: #0094b1;}
.advContent div h3{font-family: Montserrat;font-weight: 600;color: var(--fg);}
.advContent div p{color: var(--fg);font-size: 15px;}
.advantages2{display: flex;justify-content: center;align-items: center;height: 200px;background: var(--bg4);}
.advantages2 .child{margin: 0 25px;display: flex;align-items: center;flex-direction: column;color: #FFF;}
.advantages2 .child h2{font-weight: 600;font-family: Montserrat;font-size: 40px;}
.advantages2 .child h3{font-size: 20px;}
.advantages2 .child .bar{height: 3px;background: #FF0;margin-bottom: 10px;}
.team{display: flex;height: 400px;}
.team .person{display: flex;flex-direction: column;align-items: center;justify-content: center;}
.team .person h5{text-align: center;color: var(--fg);}
.team .person img{width: 250px;margin-bottom: 20px;border-radius: 20px;}
.swiper-pagination-bullet{background:var(--fg)!important;}
.swiper-pagination-bullet-active{background: #0094b1!important;}
.page404{height: 100vh;overflow: hidden;position: relative;}
.page404 .background{width: 100%;display: flex;align-items: center;justify-content: center;position: relative;}
.page404 .content{width: 70%;height: 50vh;background-color: #ffffff52;position: absolute;left: 50%;top:50%;transform: translate(-50%, -50%);display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 30px;backdrop-filter: blur(20px);color: var(--fg);}
.page404 .content h1{font-size: 70px;font-family: Montserrat;font-weight: 800;}
.teamTitle{width: 100%;text-align: center;border-bottom: 2px solid var(--fg);margin: 50px 0 20px 0;padding-bottom: 30px;}
.teamTitle h1{font-family: Montserrat;font-weight: 600;color: var(--fg);}
.contactDetailsHeader{width: 100%;display: flex;height: 90vh;overflow: hidden;margin-bottom: 20px;}
.contactDetails{display:flex;flex-direction: column;align-items: center;justify-content: space-around;width: 55vw;color: #FFF;background: #0094B1;padding: 100px 0 30px 0;}
.contactDetails h1{border-bottom: 2px solid #FFF;padding-bottom: 5px;margin-bottom: 10px;font-weight: 600;width: 50%;font-family: Merriweather;text-align: center;}
.contactThumbnail{width: 45%;position: relative;overflow: hidden;}
.contactThumbnail img{height:100%;position: absolute;left: -30%;min-height: 100%;}
.contactDetailColumn{display: flex;flex-direction: column;align-items: stretch;justify-content: space-around;}
.contactDetail{display: flex;align-items: center;margin: 15px 0;}
.contactDetail h3{font-size: 22px;}
.contactDetail i{font-size: 30px;width: 35px;margin-right:35px;display: grid;place-items: center;}
.contactSocialRow{display: flex;align-items:center}
.btnScrollToContact{display:flex;align-items: center;padding: 10px 20px;border-radius: 500px;width: 90%;z-index: 1;position: absolute;top:90%;left:50%;transform: translate(-50%, -50%);margin: 0 auto;color: #FFF;background: #0db2d3;transition: .2s;outline: none;}
.btnScrollToContact:hover{transition: .2s;scale: 1.05;left: calc(50% + 13px);top: calc(90% + 1px);}
.btnScrollToContact .x{width: 100%;}
.btnScrollToContact .x h5{text-align: center;font-weight: 600;}
.successContainer{width: 65vw;border-radius: 30px;display: flex;height: 80vh;margin: 0 auto;overflow: hidden;max-height: 700px;min-width: 720px;flex-direction: row;}
.successContainer .thumb{background: #33BA87;width: 30%;display: flex;align-items: center;justify-content: center;}
.successContainer .thumb img{width:45%;max-width: 200px;}
.successContainer .desc{display: flex;flex-direction: column;justify-content: space-between;padding: 130px 50px;align-items: center;width: 70%;border: 3px solid #33BA87;border-top-right-radius:30px;border-bottom-right-radius:30px;}
.successContainer .desc button{transition: .2s;padding: 10px 20px;border: 2px solid #33BA87;background: none;border-radius: 5px;}
.successContainer .desc button:hover{color: #FFF;background: #33BA87;transition: .2s;}
.successContainer h1{font-family: Montserrat;font-weight: 800;}
.successContainer h5{font-family: Montserrat;font-weight: 600;}
.successContainer h3{font-family: Montserrat;text-align: center;}
@keyframes rotate {
0%{transform:rotate(0)}
100%{transform:rotate(360deg)}}
@keyframes changeColor1 {
0%,100%{stroke:#e0c603}
50%{stroke:#b0f}}
@keyframes changeColor2 {
0%,100%{stroke:#00c8ff}
50%{stroke:#26ff00}}
@media (min-width:1700px) {
.header .headerContainer .headerContent{align-items:start}
.adminLogin .credentials{width:800px}
.page404 .background img{width: 100%;}}
@media (max-width:1200px) {
.navig .items .item .link{font-size:14px}}
@media (max-width:1100px) {
.successContainer{flex-direction: column;margin-top: 10px;min-width: auto;width: 80%;}
.successContainer .thumb,.successContainer .desc{width: 100%;}
.successContainer .thumb{height: 400px;}
.successContainer .thumb img{width: auto;height:50%}
.successContainer .desc{padding: 50px 30px;height: 800px;border-top-right-radius: 0;border-bottom-left-radius: 30px;}
.contactDetails{width: 100%;}
.navig .items,.contactThumbnail{display:none}
.navig .menuIcon{display:flex}
.navig{padding:15px 25px 15px 50px;height:80px;justify-content: space-between;}
.header{height:auto}
.header .bg{display:none}
.header.x{overflow:hidden}
.header:not(.z){height: 270px;}
.headerContainer{height:900px!important;flex-direction:column;align-items:center!important;background:linear-gradient(-45deg,#0094B1,#002C66);padding:30px;justify-content: end!important;padding-bottom: 50px!important;}
.headerContainer:not(.z){height:275px!important;}
.headerContainer .headerTitle h1{text-align:center}
.headerContainer .headerContent{width:100%!important}
.headerContainer .headerTitle{width:100%!important}
.headerButtons{justify-content:center;width: 100%;}
.cards{height:auto;flex-direction:column;margin-bottom:50px}
.card{width:80%;margin:30px;justify-content: space-evenly;}
.card:hover{transform:scale(1.01)}
.abtCompany{flex-direction:column;height:auto;width:100%}
.abtCompany .thumbnail{margin:0}
.abtCompany .body{width:90%;height:auto}
.abtCompany .body .body1{height:300px}
.abtCompany .body h1{text-align:center}
.abtCompany .body .body1 .body2 div:nth-child(2) h2{font-size:25px;font-weight:600}
.servicesRow{flex-direction:column;align-items:center}
.services .servicesRow{margin:0}
.services .servicesRow:last-child{flex-direction:column-reverse}
.services .servicesRow .card{width:90%;margin-bottom:40px}
.services .servicesRow .card div.circle {border-radius: 0;top: -297px;}
.contentt,.contentt.c2{flex-direction:column-reverse;align-items:center}
.contentt .content{width:100%;margin:0 10px}
.contentt .thumbnail{height:400px;width:90%}
.contact form{width:100%;align-items:center;display:flex;flex-direction:column}
.contact form .rowField{flex-direction:column;width:97%;margin:15px 0}
.contact form .rowField:first-child input:first-child{margin-bottom:32px}
.rowField input{width:100%}
.bttn{height:70px;margin:15px}
.primaryInput,.textArea{margin:0}
.adminLogin .credentials{width:90%}
.content{width:95%;margin: 0 auto;}
.abtHeading .content{width: 95%;}
.advantages1{flex-direction: column;height: auto;}
.adv{width: auto;margin: 20px 10px;}
.advantages2{flex-direction: column;height: auto;}
.advantages2 .child {flex-direction: row;margin: 10px 0;}
.advantages2 .child h2{width: 95px;text-align: center;}
.advantages2 .child .bar{display: none;}
.postCard:hover .thumbnail img{transform:none;filter: none;}
.content .contentRow{flex-direction: column;justify-content: center;width: 90vw;height: auto;margin: 0 auto;}
.content .contentRow img{width: 100%;height: auto}}
@media (max-width:800px) {
.postCard{width:80%}}
@media (max-width:640px) {
.header.x .headerContainer .headerTitle h1{text-align:center;font-size:40px}
.headerButtons{flex-direction:column}
.abtCompany .thumbnail{margin:0}
.header .headerContainer div:first-child{text-align:center}
.header .headerContainer .headerTitle h1{font-size:30px;width:80%;transform:scale(1.2);margin-bottom:20px}
.headerContainer .headerTitle{display:flex;justify-content:center}
.abtCompany .thumbnail{height:400px;width:400px}
.abtCompany .body .body1 .body2 div:first-child{width:120px}
.adminLogin .credentials{width:98%}}
@media (max-width:500px) {
.postCard {width: 100%;}
.post .content{width:90%}}
@media (max-width:400px) {
.abtCompany .thumbnail{height:300px;width:90%}
.headerContainer{padding: 5px;}}